import styled from 'styled-components';

import BlackBackground from '../../images/blackBackground.png';

export const StyledRealizationsWrapper = styled.section`
  max-height: 2910px;
  overflow: hidden;
  background: url(${BlackBackground}) no-repeat;
  background-position: top 291px center;
  background-size: 100% 1620px;
  @media only screen and (max-width: 425px) {
    background-size: cover;
    background-position: top 250px center;
    background-size: 100% 900px;
  }
`;

export const StyledRealizationsContent = styled.div`
  height: 100%;
  padding: 0 149px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 768px) {
    padding: 0 50px;
  }
  @media only screen and (max-width: 425px) {
    padding: 0 20px;
  }
`;

export const StyledRealizationsStepsTitles = styled.div`
  text-align: left;
  margin-bottom: 150px;
  @media only screen and (max-width: 1024px) {
    text-align: center;
    margin-bottom: 100px;
  }
`;

export const StyledRealizationsStepsSlider = styled.div`
  height: 255px;
  width: 100vw;
  @media only screen and (max-width: 425px) {
    height: 275px;
  }
  @media only screen and (max-width: 375px) {
    height: 315px;
  }
`;

export const StyledRealizationsArrow = styled.img`
  height: 41px;
  width: 41px;
  display: inline-block;
  vertical-align: middle;
`;

export const StyledRealizationsFrame = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 260px;
  width: 100vw;
  padding-left: 278px;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 0;
    position: relative;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 150px;
    margin-bottom: 10px;
  }
`;

export const StyledRealizationsTitles = styled.div`
  max-width: 728px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    padding: 0 139px;
    margin-bottom: 50px;
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 50px;
  }
  @media only screen and (max-width: 425px) {
    padding: 0 20px;
  }
`;

export const StyledRealizationsSlider = styled.div`
  height: 100%;
  width: 890px;
  @media only screen and (max-width: 1024px) {
    margin-top: 50px;
    width: 100vw;
  }
  @media only screen and (max-width: 320px) {
    margin-top: 0;
  }
`;

export const StyledRealizationsBtn = styled.div`
  height: 140px;
  width: 350px;
  display: flex;
  align-items: flex-end;

  @media only screen and (max-width: 1024px) {
    justify-content: center;
    margin: 0 auto;
  }
  @media only screen and (max-width: 768px) {
    height: 120px;
  }
  @media only screen and (max-width: 425px) {
    height: 100px;
    width: 300px;
  }
  @media only screen and (max-width: 375px) {
    width: 270px;
  }
`;

export const StyledRealizationsDownArrow = styled.img`
  height: 41px;
  width: 41px;
  @media only screen and (max-width: 1024px) {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const StyledRealizationsDivider = styled.div`
  margin: 219px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before,
  &::after {
    content: '';
    display: block;
    height: 5px;
    min-width: 200px;
    background: ${({ theme }) => theme.colors.silver};
  }
  &::before {
    margin-right: 70px;
  }

  &::after {
    margin-left: 70px;
  }
  @media only screen and (max-width: 425px) {
    margin: 140px 0;
    &::before {
      margin-right: 20px;
    }

    &::after {
      margin-left: 20px;
    }
  }
`;

export const StyledRealizationsDividerIcon = styled.img``;

export const StyledRealizationsVideoBox = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 235px;
  position: relative;
  @media only screen and (max-width: 425px) {
    margin: 0;
  }
`;
export const StyledRealizationsVideo = styled.video`
  width: 100%;
  height: 100%;
`;

export const StyledRealizationsPlayButton = styled.div`
  display: ${({ show }) => (show ? 'none' : 'block')};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: red;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
  height: 17%;
  width: 9.5%;
`;

export const StyledRealizationsPlayIcon = styled.img`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
