import styled from "styled-components";

export const StyledSliderWrapper = styled.div`
  position: relative;
  max-width: 1070px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    max-width: 650px;
  }
`;

export const StyledArrowNext = styled.button`
  cursor: pointer;
  height: 59px;
  width: 39px;
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 88px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
export const StyledArrowPrev = styled.button`
  cursor: pointer;
  height: 59px;
  width: 39px;
  background: none;
  border: none;
  position: absolute;
  left: 0;
  top: 88px;
  z-index: 10;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
export const StyledArrowIcon = styled.img``;
