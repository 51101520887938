import styled from "styled-components";
import Worker from "../../images/worker.png";

export const StyledAboutWrapper = styled.section`
  height: 1000px;
  width: 100vw;
  position: relative;
  @media only screen and (max-width: 1400px) {
    display: flex;
    flex-direction: column;
    height: 1700px;
  }
  @media only screen and (max-width: 890px) {
    height: 1850px;
  }
  @media only screen and (max-width: 768px) {
    height: 1200px;
  }
  @media only screen and (max-width: 575px) {
    height: 1350px;
  }
  @media only screen and (max-width: 575px) {
    height: 1450px;
  }
  @media only screen and (max-width: 375px) {
    height: 1550px;
  }
`;

export const StyledAboutContent = styled.div`
  padding-top: 39px;
  padding-left: 149px;
  @media only screen and (max-width: 768px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 425px) {
    padding-left: 20px;
  }
`;

export const StyledAboutLogo = styled.div`
  position: absolute;
  display: flex;
  top: 191px;
  right: 686px;
  width: 255px;
  height: 259px;
  z-index: 60;

  @media only screen and (max-width: 1815px) {
    right: 472.5px;
  }

  @media only screen and (max-width: 1400px) {
    top: 58px;
    right: 50px;
  }
  @media only screen and (max-width: 768px) {
    top: 90px;
    right: 0;
    width: 25%;
    height: 25%;
  }
`;

export const StyledAboutLogoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const StyledAboutLogoLeft = styled.img`
  position: absolute;
  @media only screen and (max-width: 1400px) {
    width: 100%;
    height: auto;
  }
`;
export const StyledAboutLogoRight = styled.img`
  position: absolute;
  right: 0px;
  opacity: 50%;
  @media only screen and (max-width: 1400px) {
    display: none;
  }
`;
export const StyledAboutWorkerImage = styled.img`
  height: 987px;
  width: 814px;
  position: absolute;
  right: 0;
  bottom: 163px;
  z-index: 50;

  @media only screen and (max-width: 1815px) {
    width: 600px;
    height: auto;
    bottom: 340px;
  }

  @media only screen and (max-width: 1400px) {
    bottom: 100px;
    left: calc((100vw - 600px) / 2);
  }
  @media only screen and (max-width: 768px) {
    width: 300px;
    left: calc((100vw - 300px) / 2);
    height: auto;
  }
`;

export const StyledAboutDescWrapper = styled.div`
  height: 100%;
  width: 674px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1815px) {
    max-width: 850px;
    width: 51%;
  }
  @media only screen and (max-width: 1625px) {
    width: 41%;
  }
  @media only screen and (max-width: 1400px) {
    width: 80vw;
  }
  @media only screen and (max-width: 768px) {
    width: 95vw;
  }
`;

export const StyledAboutTitle = styled.h2`
  color: ${({ theme }) => theme.colors.yellow};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

export const StyledAboutSubTitle = styled.h3`
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin: 0 0 50px 0;
  @media only screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
  }

  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

export const StyledAboutDescription = styled.p`
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  text-align: left;
  width: 100%;
  margin: 0 0 20px 0;
  @media only screen and (max-width: 1400px) {
    width: 85%;
  }

  @media only screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;
