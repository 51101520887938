import styled from 'styled-components';
import heroBackground from '../../images/backgroundHero.jpeg';

export const StyledHeroWrapper = styled.section`
  background: url(${heroBackground}) no-repeat center;
  height: 90vh;
  width: 100vw;
  background-size: cover;
  z-index: 10;
  position: relative;
`;

export const StyledHeroConent = styled.div`
  padding-top: 250px;
  padding-left: 149px;
  @media only screen and (max-width: 768px) {
    padding-top: 300px;
    padding-left: 50px;
  }
  @media only screen and (max-width: 425px) {
    padding-left: 20px;
  }
`;

export const StyledHeroButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  background-color: ${({ theme }) => theme.colors.lighterBlack};
  font-family: Poppins, sans-serif;
  text-decoration: none;
  padding: 14px 22px;
  border-radius: 8px;
  outline: none;
  position: absolute;
  width: 260px;
  height: 52px;
  transition: all 0.3s;
  margin-top: 150px;
  display: flex;
  align-items: center;

  svg {
    margin-left: auto;
  }

  svg path {
    transition: fill 0.3s;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.yellow};
    svg path {
      fill: ${({ theme }) => theme.colors.yellow};
    }
  }
  @media only screen and (max-width: 768px) {
    margin-top: 100px;
    width: 240px;
    height: 45px;
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  }
  @media only screen and (max-width: 375px) {
    width: 200px;
  }
`;
