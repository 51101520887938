import styled from "styled-components";

export const StyledBenefitsWrapper = styled.section`
  height: 1360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media only screen and (max-width: 1180px) {
    height: 1550px;
  }
  @media only screen and (max-width: 768px) {
    height: 1590px;
  }
  @media only screen and (max-width: 550px) {
    height: 1450px;
  }
  @media only screen and (max-width: 375px) {
    height: 1470px;
  }
`;

export const StyledBenefitsContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  z-index: 50;
  @media only screen and (max-width: 1180px) {
    flex-direction: column;
  }
`;

export const StyledBenefitsAccordionWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 163px;
  padding-left: 56px;
  @media only screen and (max-width: 1180px) {
    padding-left: 0%;
    padding-top: 50px;
    display: flex;
    justify-content: center;
  }
`;

export const StyledBenefitsImage = styled.img`
  padding-left: 149px;
  padding-top: 260px;
  @media only screen and (max-width: 1180px) {
    margin: 0 auto;
    padding-top: 80px;
    padding-left: 0%;
  }
  @media only screen and (max-width: 550px) {
    width: 90%;
    height: auto;
  }
`;
