import React from "react";
import {
  StyledAboutContent,
  StyledAboutDescription,
  StyledAboutDescWrapper,
  StyledAboutLogo,
  StyledAboutLogoLeft,
  StyledAboutLogoRight,
  StyledAboutLogoWrapper,
  StyledAboutSubTitle,
  StyledAboutTitle,
  StyledAboutWorkerImage,
  StyledAboutWrapper,
} from "./about.style";
import { LANG } from "../../lang/lang";
import { StyledCenter } from "../../utils/styledComponents/center.style";
import Logo from "../../images/logo2.png";
import Worker from "../../images/worker.jpeg";

const AboutUs = (): JSX.Element => {
  return (
    <StyledAboutWrapper>
      <StyledCenter>
        <StyledAboutContent>
          <StyledAboutDescWrapper>
            <StyledAboutTitle>{LANG.PL.aboutSection.title}</StyledAboutTitle>
            <StyledAboutSubTitle>
              {LANG.PL.aboutSection.subTitle}
            </StyledAboutSubTitle>
            <StyledAboutDescription>
              {LANG.PL.aboutSection.desc_1}
            </StyledAboutDescription>
            <StyledAboutDescription>
              {LANG.PL.aboutSection.desc_2}
            </StyledAboutDescription>
            <StyledAboutDescription>
              {LANG.PL.aboutSection.desc_3}
            </StyledAboutDescription>
          </StyledAboutDescWrapper>
        </StyledAboutContent>
      </StyledCenter>
      <StyledAboutLogo>
        <StyledAboutLogoWrapper>
          <StyledAboutLogoLeft src={Logo} />
        </StyledAboutLogoWrapper>
        <StyledAboutLogoWrapper>
          <StyledAboutLogoRight src={Logo} />
        </StyledAboutLogoWrapper>
      </StyledAboutLogo>
      <StyledAboutWorkerImage src={Worker}></StyledAboutWorkerImage>
    </StyledAboutWrapper>
  );
};

export default AboutUs;
