import styled from "styled-components";

export const StyledSliderItem = styled.div`
  height: 255px;
  width: 812px;
  margin: 0 auto;
  overflow: visible;
  position: relative;
  @media only screen and (max-width: 1024px) {
    max-width: 550px;
  }
  @media only screen and (max-width: 425px) {
    max-width: 90%;
    height: 275px;
  }
  @media only screen and (max-width: 375px) {
    max-width: 90%;
    height: 315px;
  }
`;

export const StyledSliderItemLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.lighterBlack};
  margin: 0 auto;
  padding: 25px 40px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 81px;
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const StyledSliderItemContent = styled.div`
  background: ${({ theme }) => theme.colors.brightYellow};
  height: fit-content;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 100%;
  height: 174px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 425px) {
    height: 194px;
  }
  @media only screen and (max-width: 375px) {
    height: 234px;
  }
`;

export const StyledSliderItemDesc = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.lighterBlack};
  margin: 0;
  padding: 25px 40px;
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;
