import React from "react";

import Layout from "../components/layout/layout.component";
import Hero from "../sections/hero/hero.component";
import About from "../sections/about/about.component";
import Benefits from "../sections/benefits/benefits.component";
import Realizations from "../sections/realizations/realizations.component";
import Contact from "../components/contact/contact.component";
import Footer from "../sections/footer/footer.component";
import SEO from "../components/seo.component";
import { LANG } from "../lang/lang";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title={LANG.PL.seoTitle} />
      <Hero />
      <About />
      <Benefits />
      <Realizations />
      <Contact />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
