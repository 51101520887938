import styled, { css } from 'styled-components';

export const StyledAccordionWrapper = styled.ul`
  width: 80%;
  list-style: none;
  padding: 0;
  @media only screen and (max-width: 1250px) {
    width: 85%;
  }
  @media only screen and (max-width: 550px) {
    width: 95%;
  }
`;

export const StyledAccordionItem = styled.li`
  position: relative;
  margin-bottom: 40px;
`;
export const StyledAccordionLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.lighterBlack};
  position: relative;
  padding: 28px 3.2% 28px 6.5%;
  background: ${({ active, theme }) =>
    active ? theme.colors.mint : theme.colors.white};
  border-radius: 16px;
  border-bottom-left-radius: ${({ active }) => (active ? '0' : '16px')};
  border-bottom-right-radius: ${({ active }) => (active ? '0' : '16px')};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  text-align: left;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.mint};
  }
  @media only screen and (max-width: 650px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

const activeStyles = `
border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  max-height: 280px;
  @media only screen and (max-width: 1024px) {
    max-height: 200px;
  }
  @media only screen and (max-width: 768px) {
    max-height: 250px;
  }
  @media only screen and (max-width: 425px) {
    max-height: 290px;
  }
  @media only screen and (max-width: 375px) {
    max-height: 340px;
  }
`;

export const StyledAccordionContent = styled.div`
  background: ${({ theme }) => theme.colors.mint};
  position: relative;
  max-height: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;

  ${({ active }) =>
    active
      ? css`
          ${activeStyles}
        `
      : ''}
`;

export const StyledAccordionDesc = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.lighterBlack};
  padding-left: 6.5%;
  padding-right: 3.5%;
  padding-bottom: 28px;
  margin: 0;
  text-align: left;
  @media only screen and (max-width: 650px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const StyledAccordionBtn = styled.button`
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  height: 36px;
`;

export const StyledAccordionIcon = styled.img``;
