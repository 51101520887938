import React from "react";

import { StyledRegularTitle } from "../../utils/styledComponents/regularTitle.style";
import { StyledCenter } from "../../utils/styledComponents/center.style";
import {
  StyledBenefitsAccordionWrapper,
  StyledBenefitsContent,
  StyledBenefitsImage,
  StyledBenefitsWrapper,
} from "./benefits.style";
import Accordion from "../../components/accordion/accordion.component";
import { LANG } from "../../lang/lang";
import HouseImage from "../../images/houseImage.png";

const Benefits = (): JSX.Element => {
  const accordionItems = Object.entries(LANG.PL.benefitsSection.benefits).map(
    (e) => e[1]
  );
  return (
    <StyledCenter>
      <StyledBenefitsWrapper>
        <StyledRegularTitle>{LANG.PL.benefitsSection.title}</StyledRegularTitle>
        <StyledBenefitsContent>
          <StyledBenefitsImage src={HouseImage} />
          <StyledBenefitsAccordionWrapper>
            <Accordion items={accordionItems}></Accordion>
          </StyledBenefitsAccordionWrapper>
        </StyledBenefitsContent>
      </StyledBenefitsWrapper>
    </StyledCenter>
  );
};

export default Benefits;
