import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import {
  StyledImageSliderItem,
  StyledImageSliderWrapper,
  StyledSliderItem,
  StyledImageTitle,
  StyledImageCity,
} from './imageSlider.style';
import Realization_1 from '../../images/realization_1.jpg';
import Realization_2 from '../../images/realization_2.jpg';
import Realization_3 from '../../images/realization_3.jpg';
import { LANG } from '../../lang/lang';

const ImageSlider = (): JSX.Element => {
  const [breakPoint, setBreakPoint] = useState(50);

  useEffect(() => {
    window.innerWidth <= 1024 ? setBreakPoint(44) : null;
    window.innerWidth <= 425 ? setBreakPoint(100) : null;
  }, []);

  return (
    <StyledImageSliderWrapper>
      <Carousel
        infiniteLoop
        centerMode
        centerSlidePercentage={breakPoint}
        showStatus={false}
        autoPlay={true}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
      >
        <StyledSliderItem>
          <StyledImageSliderItem src={Realization_1} />
          <StyledImageTitle>
            {LANG.PL.realizationsSection.realizationsTitle.picture_1.title}
          </StyledImageTitle>
          <StyledImageCity>
            {LANG.PL.realizationsSection.realizationsTitle.picture_1.subTitle}
          </StyledImageCity>
        </StyledSliderItem>
        <StyledSliderItem>
          <StyledImageSliderItem src={Realization_2} />
          <StyledImageTitle>
            {LANG.PL.realizationsSection.realizationsTitle.picture_2.title}
          </StyledImageTitle>
          <StyledImageCity>
            {LANG.PL.realizationsSection.realizationsTitle.picture_2.subTitle}
          </StyledImageCity>
        </StyledSliderItem>
        <StyledSliderItem>
          <StyledImageSliderItem src={Realization_3} />
          <StyledImageTitle>
            {LANG.PL.realizationsSection.realizationsTitle.picture_3.title}
          </StyledImageTitle>
          <StyledImageCity>
            {LANG.PL.realizationsSection.realizationsTitle.picture_3.subTitle}
          </StyledImageCity>
        </StyledSliderItem>
      </Carousel>
    </StyledImageSliderWrapper>
  );
};

export default ImageSlider;
