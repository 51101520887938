import React from "react";

import SliderItem from "./sliderItem.component";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  StyledArrowIcon,
  StyledArrowNext,
  StyledArrowPrev,
  StyledSliderWrapper,
} from "./slider.style";
import { LANG } from "../../lang/lang";
import LeftArrow from "../../images/arrowSliderLeft.svg";
import RightArrow from "../../images/arrowSliderRight.svg";

const Slider = (): JSX.Element => {
  const slides = Object.entries(LANG.PL.realizationsSection.slides).map(
    (e) => e[1]
  );

  const sliderItem = slides.map((el) => {
    return (
      <SliderItem
        key={el.title}
        title={el.title}
        description={el.description}
      />
    );
  });

  return (
    <StyledSliderWrapper>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <StyledArrowNext onClick={onClickHandler}>
              <StyledArrowIcon src={RightArrow} />
            </StyledArrowNext>
          )
        }
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <StyledArrowPrev onClick={onClickHandler}>
              <StyledArrowIcon src={LeftArrow} />
            </StyledArrowPrev>
          )
        }
      >
        {sliderItem}
      </Carousel>
    </StyledSliderWrapper>
  );
};

export default Slider;
