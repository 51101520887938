import styled from 'styled-components';

export const StyledImageSliderWrapper = styled.div`
  height: 100%;
`;

export const StyledSliderItem = styled.div`
  transform: translateX(-220px);
  margin: 23px 20px 0 20px;
  width: 405px;
  height: 550px;
  position: relative;
  @media only screen and (max-width: 1024px) {
    transform: translateX(0);
  }
  @media only screen and (max-width: 768px) {
    width: 300px;
  }
  @media only screen and (max-width: 425px) {
    width: 80%;
    height: 500px;
    margin: 0 auto 0 auto;
  }
  @media only screen and (max-width: 375px) {
    height: 440px;
    margin: 0 auto 0 auto;
  }
`;

export const StyledImageSliderItem = styled.img`
  border-radius: 20px;
  @media only screen and (max-width: 1024px) {
    width: auto;
    max-height: 550px;
  }
`;

export const StyledImageTitle = styled.p`
  position: absolute;
  bottom: 16%;
  left: 5%;
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.white};
  @media only screen and (max-width: 768px) {
    bottom: 38%;
  }
  @media only screen and (max-width: 425px) {
    bottom: 23%;
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
  @media only screen and (max-width: 320px) {
    bottom: 33%;
  }
`;

export const StyledImageCity = styled.p`
  position: absolute;
  left: 5%;
  bottom: 12%;
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.white};
  @media only screen and (max-width: 768px) {
    bottom: 34%;
  }
  @media only screen and (max-width: 425px) {
    bottom: 19%;
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
  }
  @media only screen and (max-width: 320px) {
    bottom: 29%;
  }
`;
