import React from "react";
import { Link } from "gatsby";
import {
  StyledHeroButton,
  StyledHeroConent,
  StyledHeroWrapper,
} from "./hero.style";
import { StyledRegularTitle } from "../../utils/styledComponents/regularTitle.style";
import { StyledBoldTitle } from "../../utils/styledComponents/boldTitle.style";
import { StyledCenter } from "../../utils/styledComponents/center.style";
import { LANG } from "../../lang/lang";

const Hero = (): JSX.Element => {
  return (
    <StyledHeroWrapper>
      <StyledCenter>
        <StyledHeroConent>
          <StyledBoldTitle>{LANG.PL.heroSection.title}</StyledBoldTitle>
          <StyledRegularTitle>
            {LANG.PL.heroSection.subTitle}
          </StyledRegularTitle>
          <StyledHeroButton as={Link} to={LANG.PL.heroSection.button.url}>
            {LANG.PL.heroSection.button.title}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8.882"
              height="13.691"
              viewBox="0 0 8.882 13.691"
            >
              <path
                id="Arrow_Caret_Back"
                data-name="Arrow Caret Back"
                d="M.517,8.026a1.607,1.607,0,0,1,0-2.361L6.184.43a1.607,1.607,0,0,1,2.7,1.181V12.081a1.607,1.607,0,0,1-2.7,1.181Z"
                transform="translate(8.882 13.691) rotate(180)"
                fill="#fff"
              />
            </svg>
          </StyledHeroButton>
        </StyledHeroConent>
      </StyledCenter>
    </StyledHeroWrapper>
  );
};

export default Hero;
