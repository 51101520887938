import React, { useState } from 'react';
import {
  StyledAccordionWrapper,
  StyledAccordionItem,
  StyledAccordionLabel,
  StyledAccordionBtn,
  StyledAccordionIcon,
  StyledAccordionContent,
  StyledAccordionDesc,
} from './accordion.style';
import PlusIcon from '../../images/plusIcon.png';
import MinusIcon from '../../images/minusIcon.png';

type AccordionItem = {
  id: number;
  title: string;
  description: string;
};

type AccordionPropTypes = {
  items: AccordionItem[];
};

const Accordion = ({ items }: AccordionPropTypes): JSX.Element => {
  const [activeId, setActiveId] = useState(null);

  const renderAccordionItem = () => {
    return items.map((el) => (
      <StyledAccordionItem key={el.id}>
        <StyledAccordionLabel
          onClick={() => setActiveId(activeId !== el.id ? el.id : null)}
          active={activeId === el.id}
        >
          {el.title}
          <StyledAccordionBtn>
            <StyledAccordionIcon
              src={activeId === el.id ? MinusIcon : PlusIcon}
            />
          </StyledAccordionBtn>
        </StyledAccordionLabel>
        <StyledAccordionContent active={activeId === el.id}>
          <StyledAccordionDesc>{el.description}</StyledAccordionDesc>
        </StyledAccordionContent>
      </StyledAccordionItem>
    ));
  };

  return (
    <StyledAccordionWrapper>{renderAccordionItem()}</StyledAccordionWrapper>
  );
};

export default Accordion;
