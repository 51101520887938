import React, { useRef, useState } from 'react';

import {
  StyledRealizationsArrow,
  StyledRealizationsBtn,
  StyledRealizationsContent,
  StyledRealizationsDivider,
  StyledRealizationsDividerIcon,
  StyledRealizationsDownArrow,
  StyledRealizationsFrame,
  StyledRealizationsPlayButton,
  StyledRealizationsPlayIcon,
  StyledRealizationsSlider,
  StyledRealizationsStepsSlider,
  StyledRealizationsStepsTitles,
  StyledRealizationsTitles,
  StyledRealizationsVideo,
  StyledRealizationsVideoBox,
  StyledRealizationsWrapper,
} from './realizations.style';
import { StyledBoldTitle } from '../../utils/styledComponents/boldTitle.style';
import { StyledRegularTitle } from '../../utils/styledComponents/regularTitle.style';
import { StyledCenter } from '../../utils/styledComponents/center.style';
import Slider from '../../components/slider/slider.component';
import ImageSlider from '../../components/imageSlider/imageSlider.component';
import Button from '../../components/button/button.component';
import { LANG } from '../../lang/lang';
import PlayButton from '../../images/playButton.png';
import OrangeArrow from '../../images/orangeArrow.png';
import OrangeArrowRight from '../../images/orangeArrowRight.png';
import Video from '../../images/video.mp4';
import DividerIcon from '../../images/smallVoltspotIcon.png';
import Poster from '../../images/poster.jpeg';

const Realizations = (): JSX.Element => {
  const playBtn = useRef(null);
  const [controls, setControls] = useState(false);

  const onPlayHandler = () => {
    playBtn.current.play();
    setControls(true);
  };
  return (
    <StyledRealizationsWrapper>
      <StyledCenter>
        <StyledRealizationsContent>
          <StyledRealizationsStepsTitles>
            <StyledBoldTitle>
              {LANG.PL.realizationsSection.stepsTitle.title}
            </StyledBoldTitle>
            <StyledRegularTitle>
              {LANG.PL.realizationsSection.stepsTitle.subTitle}{' '}
              <StyledRealizationsArrow src={OrangeArrow} />
            </StyledRegularTitle>
          </StyledRealizationsStepsTitles>
          <StyledRealizationsStepsSlider>
            <Slider />
          </StyledRealizationsStepsSlider>
          <StyledRealizationsFrame>
            <StyledRealizationsTitles>
              <StyledBoldTitle white={true}>
                {LANG.PL.realizationsSection.realizationsTitle.title}
              </StyledBoldTitle>
              <StyledRegularTitle white={true}>
                {LANG.PL.realizationsSection.realizationsTitle.subTitle}
                <StyledRealizationsDownArrow src={OrangeArrowRight} />
              </StyledRegularTitle>
              <StyledRealizationsBtn>
                <Button yellow={true} type={'link'} to={'realizacje'}>
                  {LANG.PL.realizationsSection.button}
                </Button>
              </StyledRealizationsBtn>
            </StyledRealizationsTitles>
            <StyledRealizationsSlider>
              <ImageSlider />
            </StyledRealizationsSlider>
          </StyledRealizationsFrame>
          <StyledRealizationsVideoBox>
            <StyledRealizationsVideo
              ref={playBtn}
              controls={controls}
              poster={Poster}
            >
              {' '}
              <source src={Video} type="video/mp4" />
            </StyledRealizationsVideo>
            <StyledRealizationsPlayButton
              show={controls}
              onClick={onPlayHandler}
            >
              <StyledRealizationsPlayIcon src={PlayButton} />
            </StyledRealizationsPlayButton>
          </StyledRealizationsVideoBox>
          <StyledRealizationsDivider>
            <StyledRealizationsDividerIcon src={DividerIcon} />
          </StyledRealizationsDivider>
        </StyledRealizationsContent>
      </StyledCenter>
    </StyledRealizationsWrapper>
  );
};

export default Realizations;
