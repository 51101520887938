import React from "react";

import {
  StyledSliderItem,
  StyledSliderItemContent,
  StyledSliderItemDesc,
  StyledSliderItemLabel,
} from "./sliderItem.style";

type SliderItemPropsType = {
  title: string;
  description: string;
};

const SliderItem = ({
  title,
  description,
}: SliderItemPropsType): JSX.Element => {
  return (
    <StyledSliderItem>
      <StyledSliderItemLabel>{title}</StyledSliderItemLabel>
      <StyledSliderItemContent>
        <StyledSliderItemDesc>{description}</StyledSliderItemDesc>
      </StyledSliderItemContent>
    </StyledSliderItem>
  );
};

export default SliderItem;
